import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, nofollow }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            icon
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const currentPage = typeof window !== "undefined" ? window.location.href : "";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          rel: "shortcut icon",
          type: "image/png",
          href: `${site.siteMetadata.icon}`,
        },
        {
          rel: "canonical",
          href: currentPage,
        },
      ]}
      script={[{ src: "https://cdn.splitbee.io/sb.js", async: true }]}
      title={`${metaTitle} | Billigform.dk`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `robots`,
          content: nofollow ? "noindex, nofollow" : "index, follow",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `da`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
